
.main-container {
  display: flex;
  height: 300vh;
  position: relative;
  width: 100%;
}

.elements-sidebar {
  width: 200px;
  background-color: #e0e0e0;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  transition: transform 0.3s ease;
  text-align: center;
  margin: 0 auto;
  vertical-align: middle;
  justify-content: center;
}

.editor {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  transition: margin-left 0.3s ease;
}

.draggable-element {
  position: absolute;
  z-index: 10000;
  display: inline-block;
}

.toggle-sidebar {
  position: fixed;
  left: 200px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000000000; /* Ensure the button is on top */
  padding: 10px;
  background-color: #b63418;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0; /* Rounded corners on the right side */
  transition: left 0.3s ease;
  border-radius: 0 5px 5px 0;
}

.toggle-sidebar:hover {
  background-color: #72210f;
}

.toggle-sidebar span {
  display: inline-block;
}

.editor.without-sidebar .toggle-sidebar {
  left: 0;
}

.sidebar-element {
  position: relative;
  z-index: 1000000000;
  cursor: grab !important;
  margin-bottom: 10px;
}

.move-icon {
  cursor: grab !important;
}

.element-settings {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.element-settings-button {
  position: relative;
  top: 0;
  right: 0;
  background-color: #b63418;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.element-settings li:first-child > .element-settings-button {
  border-radius: 5px 0 0 5px;
}

.element-settings li:last-child > .element-settings-button {
  border-radius: 0 5px 5px 0;
}

.element-settings-button:hover {
  background-color: #d35032;
}