.header1 {
  color: #b63418;
  text-decoration: none;
  font-size: 2rem;
  font-family: "GloberBold", sans-serif;
  font-weight: 400;
  margin: 0;
  line-height: 1.2;
  margin-top: 0;
  outline: none;
  text-transform: uppercase;
}
  