.pagination-container {
    text-align: center
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    font-size: 12.8px;
    font-family: "Open Sans", sans-serif;
}

.pagination>li {
    display: inline
}

.pagination>li>button,.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.428571429;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd
}

.pagination>li:first-child>button,.pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.pagination>li:last-child>button,.pagination>li:last-child>span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.pagination>li>button:hover,.pagination>li>span:hover,.pagination>li>button:focus,.pagination>li>span:focus {
    background-color: #eee
}

.pagination>.active>button,.pagination>.active>span,.pagination>.active>button:hover,.pagination>.active>span:hover,.pagination>.active>button:focus,.pagination>.active>span:focus {
    z-index: 2;
    color: #fff !important;
    cursor: default;
    background-color: #a42616;
    border-color: #d4310f
}

.pagination>.disabled>span,.pagination>.disabled>button,.pagination>.disabled>button:hover,.pagination>.disabled>button:focus {
    color: #999 !important;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd
}

.pagination-lg>li>button,.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px
}

.pagination-lg>li:first-child>button,.pagination-lg>li:first-child>span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px
}

.pagination-lg>li:last-child>button,.pagination-lg>li:last-child>span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.pagination-sm>li>button,.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px
}

.pagination-sm>li:first-child>button,.pagination-sm>li:first-child>span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}

.pagination-sm>li:last-child>button,.pagination-sm>li:last-child>span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.pager {
    padding-left: 0;
    margin: 20px 0;
    text-align: center;
    list-style: none
}

.pager:before,.pager:after {
    display: table;
    content: " "
}

.pager:after {
    clear: both
}

.pager:before,.pager:after {
    display: table;
    content: " "
}

.pager:after {
    clear: both
}

.pager li {
    display: inline
}

.pager li>a,.pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px
}

.pager li>a:hover,.pager li>a:focus {
    text-decoration: none;
    background-color: #eee
}

.pager .next>a,.pager .next>span {
    float: right
}

.pager .previous>a,.pager .previous>span {
    float: left
}

.pager .disabled>a,.pager .disabled>a:hover,.pager .disabled>a:focus,.pager .disabled>span {
    color: #999;
    cursor: not-allowed;
    background-color: #fff
}
