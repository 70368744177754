.base-element {
  position: relative;
}

.element-actions {
  position: absolute;
  top: -25px;
  left: 0px;
  display: none;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: space-between;
}

.edit-icon, .delete-icon, .up-icon, .down-icon, .copy-icon {
  margin-left: 5px;
  cursor: pointer;
}

.base-element:hover .element-actions {
  display: flex;
}

.delete-icon:hover {
  color: red;
}

.up-icon:hover, .down-icon:hover, .copy-icon:hover, .edit-icon:hover, .move-icon:hover {
  color: rgb(41, 109, 255);
}
