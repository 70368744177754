.resizable-hr-container {
  position: relative;
}

.resizable-hr-content {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.react-resizable-handle {
  opacity: 0;
}

.react-resizable:hover .react-resizable-handle {
  opacity: 1;
}
