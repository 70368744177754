.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000000;
  }
  
  .edit-modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .modal-field {
    margin-bottom: 10px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

.modal-textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
  